<template>
  <v-dialog scrollable v-model="dialog" persistent max-width="100%">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn> -->

      <v-icon
        small
        class="mr-2"
        v-bind="attrs"
        v-on="on"
        @click="fnConsultarInformacionDeGuia"
      >
        mdi-eye-outline
      </v-icon>
    </template>
    <v-card v-if="placa">
      <v-card-title>
        <span class="headline"
          >Detalle, guías asignadas al vehiculo
          <strong>{{ placa }}</strong></span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <!-- cuerpo resumen guia -->
          <v-row>
            <v-col>
              <v-timeline-item
                v-for="(item, i) in items"
                :key="i"
                :color="item.color"
                :icon="item.icon"
                fill-dot
              >
                <v-card :color="item.color" dark>
                  <v-card-title class="title"> Lorem Ipsum Dolor </v-card-title>
                  <v-card-text class="white text--primary">
                    <p>
                      Lorem ipsum dolor sit amet, no nam oblique veritus.
                      Commune scaevola imperdiet nec ut, sed euismod convenire
                      principes at. Est et nobis iisque percipit, an vim zril
                      disputando voluptatibus, vix an salutandi sententiae.
                    </p>
                    <v-btn :color="item.color" class="mx-0" outlined>
                      Button
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-col>
          </v-row>
          <!-- cuerpo resumen guia -->
        </v-container>
        <small>Imagen con propositos ilustrativos - Datakalan S.A.</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn> -->
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import getMapa from '../vistasresumenes/getMapa.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  props: {
    placa: {
      type: String,
    },
    mesano: {
      type: String,
    },
  },
  components: {
    getMapa,
  },
  data() {
    return {
      //
      loading: false,
      dialog: false,
      /** */
      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        },
      ],
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    fnConsultarInformacionDeGuia() {
      this.getguias(this.placa).then((result) => {
        console.log(result);
        this.selectedGuia = result;
        console.log(this.selectedGuia);
      });
    },
  },
};
</script>
<style scoped>
th,
td {
  border-bottom: 1px solid #ddd;
}
td:nth-child(2) {
  font-size: 12px;
}
tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.659);
}
tr:hover {
  background-color: rgba(201, 196, 196, 0.672);
}
.tdTituloDivision {
  font-size: 16px;
}
</style>
