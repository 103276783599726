<template>
  <v-container class="">
    <v-row>
      <v-col>
        <GmapMap
          ref="mymap"
          :map-type-id="mapTypeId"
          :center="mapCenter"
          :zoom="mapZoom"
          style="width: 100%; height: 320px"
        >
          <GmapMarker
            :icon="'http://maps.google.com/mapfiles/ms/icons/green-dot.png'"
            :position="position"
            :draggable="false"
          />
        </GmapMap>
        <table>
          <tr>
            <td>Latitud:</td>
            <td>{{ market[1] }}</td>
          </tr>
          <tr>
            <td>Longitud:</td>
            <td>{{ market[0] }}</td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['market'],
  data() {
    return {
      mapZoom: 12,
      mapTypeId: 'hybrid',
    };
  },
  computed: {
    position() {
      let lat = parseFloat(this.market[1]);
      let lng = parseFloat(this.market[0]);
      return { lat, lng };
    },
    mapCenter() {
      let lat = parseFloat(this.market[1]);
      let lng = parseFloat(this.market[0]);
      return { lat, lng };
    },
  },
};
</script>
